import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AppState } from '~core/states/app/app.state';
import { STIL_LanguageDataService } from '~shared/services/apiSTILLanguageController';
import { Language } from '~translations/models/language.model';
import { TranslationsState } from '~translations/state/translations.state';

export const translationLanguagesResolver: ResolveFn<Language[]> = async (route, state) => {
  const translationState = inject(TranslationsState);
  const facilityId = inject(AppState).get('facilityId');

  const languages = await firstValueFrom(inject(STIL_LanguageDataService).getRecordsForParentID(facilityId, true));

  translationState.set('languages', languages);

  return languages;
};
