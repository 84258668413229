import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Input,
  Output
} from '@angular/core';
import { Dictionary } from '~shared/models/dictionary';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';
import { filter } from 'lodash';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavGroupComponent {

  private facilityState = inject(FacilityState);
  private networkState = inject(NetworkState);

  group = input<Dictionary<any>>();

  unsyncedOfflinePermitTypes = input<string[]>([]);

  @Input() isActive: boolean;

  @Input() isOpen: boolean;

  @Output() groupClick = new EventEmitter<Dictionary<any>>();

  offlineModeEnabled$ = combineLatest([ this.facilityState.moduleSettings$, this.networkState.isOffline$ ]).pipe(
    map(([ moduleSettings, isOffline ]) => !!moduleSettings?.OfflineMode && isOffline)
  );

  offlineModeEnabled = toSignal(this.offlineModeEnabled$);

  showOfflinePermitIndex = computed(() => {
    const unsyncedOfflinePermitTypes = this.unsyncedOfflinePermitTypes();
    const group = this.group();
    const offlineEnabled = this.offlineModeEnabled();

    return offlineEnabled || unsyncedOfflinePermitTypes.includes(group?.itemType);
  });

  links = computed(() => {
    const group = this.group();
    const offlineEnabled = this.offlineModeEnabled();

    let links = group?.links;

    if (offlineEnabled) {
      links = filter(links, link => {
        const name = link.name.toLowerCase();
        return name.includes('create') && !name.includes('standard');
      });
    }

    return links;
  });
}
