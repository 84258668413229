import { inject, Injectable } from '@angular/core';
import {
  Route, Router, UrlSegment, UrlTree
} from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard  {

  private router = inject(Router);
  private facilityState = inject(FacilityState);
  private networkState = inject(NetworkState);

  async canActivate(
    route: Route,
    segments: UrlSegment[]): Promise<boolean | UrlTree> {

    const appOnline = await firstValueFrom(this.networkState.appOnline$.pipe());
    const canLoad = appOnline && !!(this.facilityState.get('moduleSettings')?.CustomDashboards);
    return canLoad ? true : this.router.createUrlTree([ '/' ]);
  }
}
