import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { SafeTkTableColumn } from 'src/app/safetk-table/components/safetk-table/safetk-table.component';
import { SettingsService } from '~core/services/settings/settings.service';
import { SafeTKDB } from '~indexedDB';

export const visibleColumnTemplateMap = {
  createdDate: 'date',
  createdOffline: 'icon',
  lockedForOffline: 'checkbox',
  statusDateTime: 'date'
};

export const visibleColumnConfigMap = {
  createdOffline: {
    icon: 'checkmark',
    iconClass: 'text-green-600'
  },
  lockedForOffline: {
    icon: 'checkmark'
  }
};

@Injectable({
  providedIn: 'root'
})
export class OfflinePermitIndexResolver  {

  private indexedDB = inject(SafeTKDB);
  private settingsSvc = inject(SettingsService);

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const { permitType } = route.params;

    const indexSettings = this.settingsSvc.getFilteredSettings({
      feature: 'index',
      isStandard: false,
      permitType
    }, true);

    const colOrder = indexSettings?.settings?.columnOrder;
    const visible: string[] = [ ...(indexSettings?.settings?.visibleColumns ?? []), 'createdOffline' ];


    const tableColumns: SafeTkTableColumn[] = map(visible, (col) => {
      const tableColumn: SafeTkTableColumn = {
        editable: false,
        name: camelCase(col),
        template: visibleColumnTemplateMap?.[col] ?? 'text',
        config: {
          controlType: visibleColumnTemplateMap?.[col] ?? 'text',
          referenceProp: 'name'
        }
      };

      if (visibleColumnConfigMap?.[col]) {
        tableColumn.config = {
          ...tableColumn.config,
          ...visibleColumnConfigMap?.[col]
        };
      }

      return tableColumn;
    });

    const visibleColumns = sortBy(tableColumns, col => +colOrder?.[col.name]);

    let permits = await this.indexedDB.db.permits.where('tableName').equals(permitType)
      .toArray();

    permits = permits.map(permit => {
      permit.rowColor = indexSettings?.settings?.statusColors?.[permit?.currentStatusName] ?? 'white';
      return permit;
    });

    return {
      columns: visibleColumns,
      permits
    };
  }
}
