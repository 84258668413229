import { Placement } from 'tippy.js';

export const statusInstancesVariation = {
  placement: 'top' as Placement,
  animation: 'grow-height',
  arrow: false,
  trigger: 'mouseenter',
  offset: [ 0, 0 ] as [ number, number],
  maxWidth: 250,
  interactive: true,
  interactiveBorder: 12,
  inertia: true,
  theme: 'status-history'
};
